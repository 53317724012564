<template>
    <div id="misc">
        <!-- <img
            class="misc-mask"
            height="226"
            :src="
                require(`@/assets/images/misc/misc-mask-${
                    $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
            "
        /> -->

        <div class="mt-14 text-center px-5">
            <router-link
                to="/"
                class="d-flex align-center text-decoration-none justify-center"
            >
                <v-img
                    :src="require('@/assets/images/logos/logo.png')"
                    max-height="60px"
                    max-width="60px"
                    alt="logo"
                    contain
                    eager
                    class="app-logo"
                ></v-img>
            </router-link>
            <h2
                class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center mt-8"
            >
                <span class="me-2">{{ $t("welcome") }} {{ systemName }}</span>
            </h2>
            <div v-if="!isUserLoggedIn" class="my-6">
                <v-btn color="primary" to="/login" class="mb-4 mr-4">
                    {{ $t("login") }}
                </v-btn>

                <!-- <v-btn color="primary" to="/register" class="mb-4">
                    {{ $t("school_signup") }}
                </v-btn> -->
            </div>
            <div v-else class="my-6">
                <v-btn
                    v-if="userRole === 'admin'"
                    color="primary"
                    to="/admin-dashboard"
                    class="mb-4 mr-4"
                >
                    {{ $t("dashboard") }}
                </v-btn>
                <v-btn
                    v-else
                    color="primary"
                    to="/school-dashboard"
                    class="mb-4 mr-4"
                >
                    {{ $t("dashboard") }}
                </v-btn>
            </div>
        </div>
        <div class="d-flex justify-center">
            <v-img cover src="@/assets/images/misc/home_bg.svg"></v-img>
        </div>
    </div>
</template>

<script>
import { mdiAlert } from "@mdi/js";
import { Keys } from "/src/config.js";
import auth from "@/services/AuthService";
export default {
    components: {
        Keys,
    },
    setup() {
        return {
            icons: {
                mdiAlert,
            },
            systemName: Keys.VUE_APP_SYSTEM_NAME,
        };
    },
    data() {
        return {
            isUserLoggedIn: false,
            userRole: null,
        };
    },
    mounted() {
        this.isUserLoggedIn = auth.isUserLoggedIn();
        this.userRole = auth.getLoggedInUserRole();
    },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/misc.scss";
</style>
